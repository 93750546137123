export const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#23231f",
    },
    secondary: {
      main: "#ffde8d",
    },
    background: {
      default: "#23231f",
    },
    warning: {
      main: "#e02b12",
    },
    divider: "#1f9334",
  },
  spacing: 12,
  shape: {
    borderRadius: 6,
  },
};
