import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
// import lineup from "../../images/assets/lineupnewnewnew.png";
import logoCenter from "../../images/logo/COLOUR 2.png";

function Home() {
  return (
    <div className="home">
              <div className="modal-content">
              <Link className="header-logo-link logo-landing" to="/">
              <img src={logoCenter} className="app-logo" alt="logo" />
            </Link>
          <Typography className="title" variant="h6">
            Edinburgh Psych Fest 2025
          </Typography>
          <Typography className="text">£24.50 early bird ticket</Typography>
          <Typography className="text">Sun 31st August 2025</Typography>

          <Button
            href="https://www.seetickets.com/event/edinburgh-psych-fest-2025/various-venues/3138199"
            color="secondary"
            size="large"
            variant="contained"
            className="ticket-header-button"
            sx={{
              fontSize: "22px",
              borderWidth: "3px",
              margin: "20px 0",
            }}
            target="_blank"
          >
            BUY TICKETS NOW
          </Button>


        </div>
      {/* <div className="home-lineup">
        <Container maxWidth="xl">
          <div className="lineup-container">
            <img src={lineup} className="lineup-image" alt="lineup" />
          </div>
        </Container>
      </div> */}

      {/* <div className="home-tickets">
        <Container maxWidth="xl">
          <div className="home-tickets-container">
            <h2>Sunday 1st September</h2>
            <h2>Various Venues</h2>
            <Button
              href="https://www.seetickets.com/event/edinburgh-psych-fest-2024/various-venues/2751084"
              color="secondary"
              size="large"
              variant="outlined"
              sx={{
                marginTop: "20px",
                fontSize: "24px",
                padding: "10px 30px",
                borderWidth: "3px",
              }}
              target="_blank"
              className="ticket-header-button"
            >
              Buy Tickets Now
            </Button>
          </div>
        </Container>
      </div> */}
    </div>
  );
}
export default Home;
