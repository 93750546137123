import { Button, Container } from "@mui/material";
import { Link } from "react-router-dom";

function NoMatch() {
  return (
    <Container maxWidth="xl">
      <div className="no-match">
        <h1>Nothing to see here!</h1>
        <p>
          <Link to="/">
          <Button
                color="secondary"
                size="large"
                variant="outlined"
                sx={{
                  color: "white",
                  marginTop: "20px",
                  fontSize: "24px",
                  padding: "10px 50px",
                  borderColor: "white"
                }}
                target="_blank"
              >
                Go to the home page
              </Button>
              </Link>
        </p>
      </div>
    </Container>
  );
}

export default NoMatch;
